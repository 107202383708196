import React from 'react';

//@ts-ignore
import * as styles from './CtaButton.module.css';

interface Props {
    href: string;
    target?: string;
    color?: string;
}

const CtaLink: React.FC<Props> = ({ href, target, color, children }) => {
    const inlineStyle: React.CSSProperties = {};

    if (color) {
        inlineStyle.color = color;
        inlineStyle.borderColor = color;
    }

    return (
        <>
            {color && <style>{`.${styles.ctaLink}`}</style>}
            <a
                href={href}
                target={target}
                className={`${styles.ctaLink}`}
                style={inlineStyle}
            >
                {children}
            </a>
        </>
    );
};

export default CtaLink;
