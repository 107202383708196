import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import * as styles from './mobile-utility-bar.module.css';
import CtaButton from '../Global/CtaButton';

import { BuildLinkHref } from '../Global/BuildLinkHref';
//@ts-ignore
import { CTAConfiguration, CtaTsType } from '../../../types/KontentProps';
import { getMultiChoice } from '../Global/DataUtils';
import { defaultDomainCountryCodes } from '../../global/countries';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';

interface Props extends CTAConfiguration {
    calls_to_action: {
        value: CtaTsType[];
    };
}

const MobileUtilityBar: React.FC<Props> = ({ calls_to_action }) => {
    const pageMetadataContext = useContext(PageMetadataContext);
    const homeHref =
        !pageMetadataContext.preferredLanguage ||
        defaultDomainCountryCodes.indexOf(
            pageMetadataContext.preferredLanguage.toLowerCase()
        ) > -1
            ? '/'
            : '/' + pageMetadataContext.preferredLanguage?.toLowerCase();
    const useCountryCode = homeHref.split('/')[1] !== '' ? true : false;
    const countryCode = useCountryCode ? homeHref : '';
    const [scrollState, setScrollState] = useState('none');
    useEffect(() => {
        window.addEventListener('touchmove', handleScroll);
        return () => {
            window.removeEventListener('touchmove', handleScroll);
        };
    }, []);

    const handleScroll = (e: any) => {
        let scrollHeight = document.documentElement.scrollHeight; //Scroll content height
        let windowHeight = window.innerHeight;
        let scrollTop = window.pageYOffset;
        if (scrollTop > 30 && windowHeight + scrollTop <= scrollHeight - 150) {
            setScrollState('flex');
        } else {
            setScrollState('none');
        }
    };

    return (
        <div
            className={styles.wrapper}
            style={{
                display: `${scrollState}`,
            }}
        >
            {calls_to_action?.value.map((item, idx) => {
                let slugValue = BuildLinkHref(item.elements.cta_location);
                if (!slugValue.includes('shop')) {
                    slugValue = countryCode + slugValue;
                }
                const ctaLinkHref = item.elements.cta_location_anchor_point
                    ?.value
                    ? slugValue +
                      '#' +
                      item.elements.cta_location_anchor_point?.value
                    : slugValue;
                return (
                    <div key={idx} className={styles.navSection}>
                        <CtaButton
                            href={ctaLinkHref}
                            target={
                                getMultiChoice(
                                    item.elements.cta_window_action
                                ) === 'new_window'
                                    ? '_new'
                                    : '_self'
                            }
                        >
                            <div className={styles.listLabel}>
                                {item.elements.cta_label.value}
                            </div>
                        </CtaButton>
                    </div>
                );
            })}
        </div>
    );
};

export default MobileUtilityBar;

export const fragmentMobileUtilityBar = graphql`
    fragment MobileUtilityBar on kontent_item_component___mobile_utility_bar {
        elements {
            calls_to_action {
                value {
                    ... on kontent_item_content_item___cta {
                        id
                        elements {
                            cta_label {
                                value
                            }
                            cta_location {
                                value {
                                    ... on kontent_item_content_item___third_party_link {
                                        id
                                        elements {
                                            third_party_url {
                                                value
                                            }
                                        }
                                    }
                                    ... on kontent_item_layout___master_page {
                                        id
                                        elements {
                                            slug {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                            cta_location_anchor_point {
                                value
                            }
                            cta_window_action {
                                value {
                                    codename
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
